import { PropsEvent } from "../../../redux/reports/reports_types";
import EventDay from "../../events/EventDay";
import { PropsReport } from "./eventInterfaces";
import ReportCount from "./ReportCount";
import { parseEvent } from "../../../helpers/events";
import { Declination } from "../../../helpers";

const words = ["нарушение", "нарушения", "нарушений"];

const ReportEvents = ({ report, events }: PropsReport) => {
  let speed = 0;
  let bar = 0;
  let temp = 0;

  events.forEach((event: PropsEvent) => {
    // в одном событии может быть больше одной проблемы
    const parsedEvents = parseEvent(event.point, event.limit);

    parsedEvents.forEach((parsedEvent) => {
      if (parsedEvent.type == "bar_min" || parsedEvent.type == "bar_max") {
        bar++;
      } else if (parsedEvent.type == "temp_max") {
        temp++;
      } else if (parsedEvent.type == "speed_max") {
        speed++;
      }
    });
  });

  return (
    <div className="report_body">
      <div className="report_head">
        <div className="report_title">
          <span className="report_bold">События </span>за период
        </div>
      </div>
      <div className="report_body">
        <div className="report_counts">
          <div className="report_data">
            <ReportCount count={bar + temp + speed} title1="нарушений" title2="всего" />
            <ReportCount count={bar} title1={Declination(bar, words)} title2="давления" />
          </div>
          <div className="report_data">
            <ReportCount count={speed} title1={Declination(speed, words)} title2="скорости" />
            <ReportCount count={temp} title1={Declination(temp, words)} title2="температуры" />
          </div>
        </div>

        {Object.keys(report.days).map((date: string) => {
          return <EventDay day={report.days[date]} date={date} key={date} />;
        })}
      </div>
    </div>
  );
};

export default ReportEvents;
