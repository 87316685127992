import style from "../style/SensorInfo.module.scss";
export function SensorInfo({ data, index }: any) {
  return (
    <div className={style.ctnSensorInfo}>
      <div className={style.index}>{index + 1}.</div>
      <div className={style.ctnInfo}>
        <div className={style.text}>
          {data[0]} <div>bar</div>
        </div>
        <div className={`${style.text} ${style.border_left}`}>
          {data[1]} <div>°C</div>
        </div>
        <div className={`${style.text} ${style.border_left}`}>
          {data[2]} <div>%</div>
        </div>
        <div className={`${style.text} ${style.border_left}`}>{data[3]}</div>
      </div>
      {/* <div className={`${style.text} ${style.border_left}`}>{data[3]}</div> */}
    </div>
  );
}
