import React, { memo, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { IToastAppearance } from "../../types/other";
import InputMask from "react-input-mask";
import { ModalsState } from "../../redux/modals/modals_types";
import toast from "react-hot-toast";
import { genCode } from "helpers/index";
import { usePutDeviceMutation, usePostDeviceMutation } from "redux/admin/devices/DevicesApi";
import ModalInput from "../ui/Inputs/ModalInput";

const ModalAddDevice = () => {
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const { device } = useTypedSelector((state) => state.devices);
  const { toggleModal, unselectDevice } = useActions();

  const [submiting, setSubmiting] = useState<boolean>(false);
  const [iccid, setIccid] = useState<string>("");
  const [deviceId, setDeviceId] = useState<any>(null);
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [id, setId] = useState<any>(null);
  const [errors, setErrors] = useState<any>({
    device_id: [],
    code: [],
    phone: [],
    iccid: [],
  });

  const edit = () => Object.keys(device).length > 0;

  const [putDevice, { isLoading }] = usePutDeviceMutation();
  const [postDevice] = usePostDeviceMutation();

  useEffect(() => {
    if (edit()) {
      setIccid(device.iccid);
      setCode(device.code);
      setPhone(device.phone);
      setDeviceId(device.device_id);
      setId(device.id);
    }
  }, [device]);

  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_add_device" });
    resetState();
    unselectDevice();
  };

  const resetState = () => {
    setDeviceId(null);
    setCode("");
    setPhone("");
    setErrors(null);
    setId(null);
    setIccid("");
  };

  const format_phone = phone.replace(/[^0-9]/g, "");

  const handleAddDevice = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setSubmiting(true);
    const formattedIccid = iccid.trim();

    const postRequestBody = {
      device_id: +deviceId,
      code,
      phone: format_phone,
      iccid: formattedIccid,
    };

    await postDevice(postRequestBody)
      .unwrap()
      .then((payload) => {
        handleClose();
      })
      .catch((e) => {
        toast.error(e.data.message);
      })
      .finally(() => setSubmiting(false));
  };

  const handleEditDevice = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setSubmiting(true);
    const putRequestBody = {
      id,
      device_id: +deviceId,
      code,
      phone: format_phone,
      iccid: iccid,
    };
    await putDevice(putRequestBody)
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        toast.error(e.data.message);
      })
      .finally(() => setSubmiting(false));
  };

  const handleCode = () => {
    setCode(genCode);
  };

  return (
    <Modal centered show={modals.modal_add_device} onHide={handleClose} className="modal-device">
      <Modal.Header closeButton>
        {edit() ? (
          <Modal.Title>Редактировать устройство</Modal.Title>
        ) : (
          <Modal.Title>Добавить устройство</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={edit() ? handleEditDevice : handleAddDevice}>
          <ModalInput
            placeholder="device_id"
            type="number"
            value={deviceId ? deviceId : ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setDeviceId((e.target as HTMLInputElement).value);
            }}
            errors={errors?.device_id}
          />
          <ModalInput
            placeholder="ICCID"
            type="text"
            value={iccid}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setIccid((e.target as HTMLInputElement).value);
            }}
            length={18}
            errors={errors?.iccid}
          />
          <div className="a-input-group">
            <ModalInput
              placeholder="code"
              readOnly
              type="text"
              value={code ? code : ""}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setDeviceId((e.target as HTMLInputElement).value);
              }}
              errors={errors?.code}
            />
            <button type="button" className="btn btn-code" onClick={handleCode}>
              Сгенерировать код
            </button>
          </div>
          <div className="a-input-group">
            <InputMask
              mask="+7 (999) 999 99 99"
              name="phone"
              placeholder="+7"
              className="a-input"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />

            {errors?.phone?.map((error: string, index: number) => {
              return (
                <div className="a-bg-warning" key={index}>
                  {error}
                </div>
              );
            })}
          </div>
          <button
            type="submit"
            className="btn a-device__btn a-device-btn-edit"
            disabled={submiting}
          >
            Сохранить
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default memo(ModalAddDevice);
