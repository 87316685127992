import { ReactComponent as Arrow } from "../../assets/images/Arrow2.svg";
import { useActions } from "hooks/useAction";
import { useSensorWarning } from "components/violation/hooks/use-sensor-warning";
import { useTypedSelector } from "hooks/useTypedSelector";
import { SettingsState } from "redux/settings/settings_types";

const EventTime = ({ event }: { event: any }) => {
  let data;
  const { settingsLast } = useTypedSelector<SettingsState>((state) => state.settings);
  const { toggleModal, getPointID } = useActions();
  const { warningBarMax, warningBarMin, warningSpeedMax, warningTempMax, defaultWarning } =
    useSensorWarning();

  switch (event.type) {
    case "bar_max":
      data = warningBarMax({
        eventValue: event.value,
        limitBarMax: settingsLast.bar_max,
        eventIndex: event.index,
      });
      break;
    case "bar_min":
      data = warningBarMin({
        eventValue: event.value,
        limitBarMin: settingsLast.bar_min,
        eventIndex: event.index,
      });
      break;
    case "speed_max":
      data = warningSpeedMax({
        eventValue: event.value,
        limitSpeedMax: settingsLast.speed_max,
        eventIndex: event.index,
      });
      break;
    case "temp_max":
      data = warningTempMax({ eventIndex: event.index });
      break;
    default:
      data = defaultWarning();
  }

  const handlePoint = () => {
    toggleModal({ toggle: true, modal: "modal_point" });
    getPointID(event.point_id);
  };

  return (
    <div className="report_time-event">
      <div className="report_time-violation">{data.text}</div>
      <div className="report_sensor_number">{data.sensor}</div>
      <div className="report_time-param">
        {data?.icon}
        <p>
          <span className="report_time-param--bold">{event.value}</span>
          {event.units}
        </p>
      </div>

      <button className="report_time-more" onClick={() => handlePoint()}>
        <span className="time-more_text">Подробнее</span>
        <Arrow className="arrow" />
      </button>
    </div>
  );
};

export default EventTime;
