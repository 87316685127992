/* eslint-disable prettier/prettier */
import { IDevice } from "./DevicesTypes";
import api from "api/index";
import { GlobalApi } from "api/GlobalApi";

// interface response {
//   devices:
// }

export const DevicesApi = GlobalApi.injectEndpoints({
  endpoints: (build) => ({
    getDevices: build.query<IDevice[], void>({
      query: () => ({
        url: api.getDevices(),
        params: {
          "with[]": ["vehicle"],
          user: "1",
        },
      }),
      providesTags: ["Devices"],
      // transformResponse: (response: IDevice[]) => response
    }),
    putDevice: build.mutation({
      query: (body) => ({
        url: api.putDevices(body.id),
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];
        return ["Devices"];
      },
    }),
    postDevice: build.mutation<any, { device_id: number; code: string; phone: string, iccid: any }>({
      query: (body) => ({
        url: api.postDevice(),
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];
        return ["Devices"];
      },
    }),
    deleteDevice: build.mutation<any, number>({
      query: (device_id) => ({
        url: api.deleteDevice(device_id),
        method: "DELETE",
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];
        return ["Devices"];
      },
    }),
  }),
});

export const {
  useGetDevicesQuery,
  usePutDeviceMutation,
  usePostDeviceMutation,
  useDeleteDeviceMutation,
} = DevicesApi;
