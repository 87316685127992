import { CarState, ICar } from "redux/car/car_types";
import { useActions } from "../../hooks/useAction";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { ModalsState } from "../../redux/modals/modals_types";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import PointStatus from "../vehicle-view/PointStatus";
import ModalWindow from "./ModalWindow";
import { TabModes } from "types/other";
import { use } from "chai";
import { useEffect } from "react";

const ModalPoint = () => {
  const { toggleModal, getPointDetailed } = useActions();
  const { modal_loading, modal_point, modal_point_data, modal_point_id }: ModalsState =
    useTypedSelector((state: { modals: any }) => state.modals);

  useEffect(() => {
    if (modal_point_id) {
      getPointDetailed(modal_point_id);
    }
  }, [modal_point_id]);

  const { car }: CarState = useTypedSelector((state) => state.car);
  const handleClose = async () => {
    toggleModal({ toggle: false, modal: "modal_point" });
  };

  if (modal_loading || !modal_point_data || !car) {
    return (
      <ModalWindow
        dialogClassName="modal-dialog--point"
        isShow={modal_point}
        handleClose={handleClose}
      >
        <div className="input-area input-area--margin">
          <SkeletonLoading height="200px" />
        </div>
      </ModalWindow>
    );
  }

  const carWDetails: ICar = {
    ...car.car,
    last_limit: modal_point_data.last_limit,
    design: modal_point_data.last_design,
  };

  return (
    <ModalWindow
      isHeader={false}
      dialogClassName="modal-dialog modal-dialog--point"
      classBody="modal-body modal-body--padding"
      isShow={modal_point}
      handleClose={handleClose}
    >
      <PointStatus
        car={carWDetails}
        point={modal_point_data.point}
        minified={true}
        tabMode={TabModes.MAIN}
      />
    </ModalWindow>
  );
};

export default ModalPoint;
