import {
  ModalsActions,
  ModalsActionTypes,
  ConfirmationPayload,
  ToggleModalPayload,
} from "./modals_types";
import { Dispatch } from "redux";
// import { GroupActionsTypes } from "../folder/folder_types";
import api from "../../api";
import { IPoint } from "redux/map/map_types";

// открываем любую модалку
export const toggleModal = (payload: ToggleModalPayload) => {
  return async (dispatch: Dispatch<ModalsActions>) => {
    dispatch({ type: ModalsActionTypes.TOGGLE_MODAL, payload });
    dispatch({ type: ModalsActionTypes.MODAL_LOADING, payload: false });
  };
};

// открываем модалку добавления группы
export const changeModalGroupAdd = (toggle: boolean) => {
  return async (dispatch: Dispatch<ModalsActions | any>) => {
    dispatch({
      type: ModalsActionTypes.TOGGLE_MODAL,
      payload: { toggle, modal: "modal_group_add" },
    });
  };
};

// открываем модалку с подтверждением
export const toggleModalConfirm = (payload: ConfirmationPayload) => {
  return async (dispatch: Dispatch<ModalsActions>) => {
    dispatch({
      type: ModalsActionTypes.TOGGLE_MODAL_CONFIRM,
      payload,
    });
  };
};

export const changeUpdateTyre = (payload: any) => {
  return async (dispatch: Dispatch<ModalsActions | any>) => {
    dispatch({
      type: ModalsActionTypes.UPDATE_MODAL,
      payload,
    });
  };
};

// закрываем модалку с подтверждением
export const closeModalConfirm = () => {
  return async (dispatch: Dispatch<ModalsActions>) => {
    dispatch({
      type: ModalsActionTypes.TOGGLE_MODAL_CONFIRM,
      payload: {
        toggle: false,
        title: "",
        content: "",
        onConfirm: null,
        onCancel: null,
      },
    });
  };
};

export const getPoint = (point_id: number) => {
  return async (dispatch: Dispatch<ModalsActions>) => {
    try {
      const response: any = await api.getPoint(point_id);

      dispatch({
        type: ModalsActionTypes.POINT_MODAL,
        payload: response.data.point,
      });
      dispatch({ type: ModalsActionTypes.MODAL_LOADING, payload: true });
    } catch (error: any) {
      console.log("e", error.message);
      return error;
    }
  };
};

export const getPointDetailed = (point_id: number) => {
  return async (dispatch: Dispatch<ModalsActions>) => {
    try {
      dispatch({ type: ModalsActionTypes.MODAL_LOADING, payload: true });
      const response: any = await api.getPointDetailed(point_id);
      console.log(response);

      dispatch({
        type: ModalsActionTypes.POINT_MODAL,
        payload: response,
      });
    } catch (error: any) {
      console.log("e", error.message);
      return error;
    }
  };
};

export const getPointID = (point_id: any) => {
  return async (dispatch: Dispatch<ModalsActions>) => {
    dispatch({ type: ModalsActionTypes.POINT_MODAL_DETAILED, payload: point_id });
  };
};
